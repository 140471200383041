<template>
  <div class="mainpage">
    <div class="head">
      <div class="bt bt1" @click="toback">返回</div>
      <div class="bt bt2">提交</div>
    </div>
    <el-row class="bodycontain">
      <el-col class="elcolleft" :span="16">
        <div class="leftcontain">
          <div class="title">实验预习（100分）</div>
          <div class="item-contain">
            <div class="titel2">一、单选题（共1题，5.0分）</div>
            <div class="titel3">1.中国最伟大的诗人是谁（5.0分）</div>
            <div class="rediobt" v-for="(item, index) in btlist" :key="index">
              <div
                @click="clickbt1(index)"
                :class="activebtid1 === index ? 'icon iconactive' : 'icon'"
              >
                {{ item.icon }}
              </div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>

          <div class="item-contain">
            <div class="titel2">二、多选题（共1题，5.0分）</div>
            <div class="titel3">2.中国最伟大的诗人是谁（5.0分）</div>
            <div class="rediobt" v-for="(item, index) in btlist" :key="index">
              <div
                @click="clickbt2(index)"
                :class="activebtid2 === index ? 'icon iconactive' : 'icon'"
              >
                {{ item.icon }}
              </div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>

          <div class="item-contain">
            <div class="titel2">三、判断题（共1题，5.0分）</div>
            <div class="titel3">3.中国最伟大的诗人是谁（5.0分）</div>
            <el-radio-group class="radiogroup" v-model="radio">
              <el-radio :label="1">正确</el-radio>
              <el-radio :label="2">错误</el-radio>
            </el-radio-group>
          </div>

          <div class="item-contain">
            <div class="titel2">四、填空题（共1题，5.0分）</div>
            <div class="titel3">4.唐朝诗圣是___，诗仙是___。（5.0分）</div>
            <div class="rediobt">
              <div class="icon">1</div>
              <div class="name">杜甫</div>
            </div>
            <div class="rediobt">
              <div class="icon">2</div>
              <div class="name">李白</div>
            </div>
          </div>

          <div class="item-contain">
            <div class="titel2">四、简答题（共1题，5.0分）</div>
            <div class="titel3">
              5.这是题目问题，这是题目问题，这是题目问题，这是题目问题（5.0分）
            </div>
            <div class="text-editor">
              <!-- <tinymce v-model="content" :height="300" /> -->
              <Tinymce ref="editor" v-model="content" style="height: auto; border-radius: 22px" @setInput="getValue"></Tinymce>
            </div>
            <div class="titel3">
              6.这是题目问题，这是题目问题，这是题目问题，这是题目问题（5.0分）
            </div>
          </div>
        </div>
      </el-col>
      <el-col class="elcolright" :span="8">
        <div class="rightcontain">
          <div class="title2">
            <el-image class="elimage" :src="icon1" />
            <div class="text">考试时长：60分钟</div>
          </div>
          <div class="itemcontain">
            <div class="title3">一、单选题</div>
            <div class="item3">1.中国最伟大的诗人是谁？</div>
            <div class="item3">2.中国最伟大的诗人是谁？</div>
            <div class="item3">3.中国最伟大的诗人是谁？</div>
            <div class="item3">4.中国最伟大的诗人是谁？</div>
          </div>
          <div class="itemcontain">
            <div class="title3">二、填空题</div>
            <div class="item3">5.唐朝诗圣是___，诗仙是___。？</div>
            <div class="item3">6.唐朝诗圣是___，诗仙是___。？</div>
          </div>
          <div class="itemcontain">
            <div class="title3">三、判断题</div>
            <div class="item3">6.这是题目问题，这是题目问题，这是题目...</div>
            <div class="item3">7.这是题目问题，这是题目问题，这是题目...</div>
          </div>
          <div class="itemcontain">
            <div class="title3">四、填空题</div>
            <div class="item3">9.这是题目问题，这是题目问题，这是题目...</div>
            <div class="item3">10.这是题目问题，这是题目问题，这是题目...</div>
          </div>
          <div class="itemcontain">
            <div class="title3">五、简答题</div>
            <div class="item3">11.这是题目问题，这是题目问题，这是题目...</div>
            <div class="item3">12.这是题目问题，这是题目问题，这是题目...</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Customdialog from "@/components/customdialog.vue";
// import Tinymce from "@/components/Tinymce";
import Tinymce from "@/components/VueTinymce/index.vue";
export default {
  name: "mainpage",
  components: {
    Customdialog,
    Tinymce,
  },
  data() {
    return {
      activebtid1: -1,
      activebtid2: -1,
      radio: 1,
      content:'',
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/43443.png"),
      icon2: require("@/assets/coursedetails/矢量智能对象 (1).png"),
      btlist: [
        {
          icon: "A",
          name: "李白",
        },
        {
          icon: "B",
          name: "李贺",
        },
        {
          icon: "C",
          name: "陆游",
        },
        {
          icon: "D",
          name: "杜牧",
        },
      ],
    };
  },
  computed: {},
  methods: {
    clickbt1(index) {
      this.activebtid1 = index;
    },
    clickbt2(index) {
      this.activebtid2 = index;
    },
    toback() {
      this.$router.push({
        path: "/personalcenter/previewexam",
        // name: "PersonalcenterMainpage",
        // params: {
        //   id: 2,
        //   componentId: "Myexperiment",
        // },
      });
    },
    todetail() {
      this.$router.push({
        path: "/personalcenter/interactiveqadetails",
      });
    },
     // 获取富文本得值
    getValue(data) {
      this.content = data;
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      white-space: nowrap;
    }
    .bt {
      width: 89px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #288add;
      border-radius: 2px;

      color: #288add;
      user-select: none;
      cursor: pointer;
    }
    .bt1 {
      margin-right: 10px;
    }
    .bt2 {
      background: #26b5ff;
      color: white;
    }
  }
  .bodycontain {
    // border: 1px solid red;
    margin-top: 15px;
    height: 1827px;
    
    .elcolleft {
      height: 100%;
      background: #f7f7f7;
      box-sizing: border-box;
      padding: 10px;
      .leftcontain {
        // border: 1px solid red;
        height: 100%;
        box-sizing: border-box;
        padding: 20px;
        .title {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .item-contain {
          margin-top: 30px;
          .titel2 {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
          .titel3 {
            margin-top: 20px;
            margin-left: 35px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
          .rediobt {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 20px;
            margin-left: 70px;
            .icon {
              width: 40px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              border: 1px solid #cccccc;
              border-radius: 50%;
              user-select: none;
              cursor: pointer;
            }
            .iconactive {
              background: #26b5ff;
              color: white;
            }
            .name {
              margin-left: 20px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
          }
          .radiogroup {
            margin-top: 20px;
            margin-left: 70px;
          }
          .text-editor {
            margin-top: 20px;
            margin-left: 35px;
          }
        }
      }
    }
    .elcolright {
      // border: 1px solid red;
      height: 100%;
      // background: #f7f7f7;
      box-sizing: border-box;
      padding: 10px;
      box-sizing: border-box;
      .rightcontain {
        // border: 1px solid red;
        height: 1047px;
        padding: 20px;
        background: #f7f7f7;
        .title2 {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 10px;
          border-bottom: 1px solid #b3b3b3;
          .text {
            margin-left: 10px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #222222;
          }
        }
        .itemcontain {
          margin-top: 30px;

          .title3 {
            margin-top: 20px;
            margin-left: 60px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
          .item3 {
            margin-top: 10px;
            margin-left: 80px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
        }
      }
    }
  }
}
</style>
